var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_FETCH_STATUS } from '@/shared/api/constants/baseFetch.status';
import { START_FETCH_STATUS } from '@/shared/api/constants/startFetch.status';
var initialState = {
    strategyCardData: [],
    strategyCardDataStatus: BASE_FETCH_STATUS,
    updateStrategyCardDataStatus: BASE_FETCH_STATUS,
    strategyTickers: [],
    strategyRecentDeals: [],
    strategyRecentDealsStatus: BASE_FETCH_STATUS,
    strategyTickersStatus: BASE_FETCH_STATUS,
    strategyComments: null,
    strategyCommentsStatus: BASE_FETCH_STATUS,
    getMoreRepliesStatus: BASE_FETCH_STATUS,
    strategyCommentThreads: [],
    addStrategyCommentStatus: BASE_FETCH_STATUS,
    commentRepliesPagination: {},
};
var getStrategyCardData = createAsyncThunk('strategyCardSlice/getAllStrategyMarketList', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get("strategy-market/".concat(payload.id))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_1 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_1)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var updateStrategyCardData = createAsyncThunk('strategyCardSlice/updateStrategyCardData', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.patch("/strategy-market/".concat(payload.id, "/"), payload)];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_2 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_2)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getStrategyTickers = createAsyncThunk('strategyCardSlice/getStrategyTickers', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get("strategy-market/".concat(payload.id, "/tickers"))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data.tickers];
            case 2:
                e_3 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_3)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getStrategyRecentDeals = createAsyncThunk('strategyCardSlice/getStrategyRecentDeals', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get("strategy-market/".concat(payload.id, "/recent_deals"))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data.deals];
            case 2:
                e_4 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_4)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var addFavoriteStrategy = createAsyncThunk('strategyCardSlice/addFavoriteStrategy', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.put("strategy-market/".concat(payload.id, "/favorite_strategy/"))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_5 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_5)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var removeFavoriteStrategy = createAsyncThunk('strategyCardSlice/removeFavoriteStrategy', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.delete("strategy-market/".concat(payload.id, "/favorite_strategy/"))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_6 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_6)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var fetchRepliesRecursively = function (strategyId, comment, api, page, page_size) { return __awaiter(void 0, void 0, void 0, function () {
    var repliesResp, repliesWithNested;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!(comment.reply_count > 0)) return [3 /*break*/, 3];
                return [4 /*yield*/, api.get("strategy-market/".concat(strategyId, "/comments/").concat(comment.id), {
                        params: { page: page, page_size: page_size },
                    })];
            case 1:
                repliesResp = _a.sent();
                return [4 /*yield*/, Promise.all(repliesResp.data.results.map(function (reply) {
                        return fetchRepliesRecursively(strategyId, reply, api, 1, 3);
                    }))];
            case 2:
                repliesWithNested = _a.sent();
                return [2 /*return*/, __assign(__assign({}, comment), { replies: repliesWithNested, replyPagination: {
                            currentPage: page,
                            currentPageSize: page_size,
                            totalCount: comment.reply_count,
                        } })];
            case 3: return [2 /*return*/, __assign(__assign({}, comment), { replies: [], replyPagination: { currentPage: 1, currentPageSize: 0, totalCount: 0 } })];
        }
    });
}); };
var getStrategyComments = createAsyncThunk('strategyCardSlice/getStrategyComments', function (_a, thunkAPI) {
    var id = _a.id, _b = _a.page, page = _b === void 0 ? 1 : _b, _c = _a.page_size, page_size = _c === void 0 ? 5 : _c;
    return __awaiter(void 0, void 0, void 0, function () {
        var resp, commentsWithReplies, e_7;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _d.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, thunkAPI.extra.api.get("strategy-market/".concat(id, "/comments"), {
                            params: { page: page, page_size: page_size },
                        })];
                case 1:
                    resp = _d.sent();
                    return [4 /*yield*/, Promise.all(resp.data.results.map(function (comment) {
                            return fetchRepliesRecursively(id, comment, thunkAPI.extra.api, 1, 3);
                        }))];
                case 2:
                    commentsWithReplies = _d.sent();
                    return [2 /*return*/, { count: resp.data.count, results: commentsWithReplies, page: page, page_size: page_size }];
                case 3:
                    e_7 = _d.sent();
                    return [2 /*return*/, thunkAPI.rejectWithValue(e_7)];
                case 4: return [2 /*return*/];
            }
        });
    });
});
var addStrategyComment = createAsyncThunk('strategyCardSlice/addStrategyComment', function (_a, thunkAPI) {
    var id = _a.id, text = _a.text, parent = _a.parent;
    return __awaiter(void 0, void 0, void 0, function () {
        var resp, e_8;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, thunkAPI.extra.api.post("strategy-market/".concat(id, "/comments/"), {
                            text: text,
                            parent: parent !== null && parent !== void 0 ? parent : null,
                        })];
                case 1:
                    resp = _b.sent();
                    return [2 /*return*/, resp.data];
                case 2:
                    e_8 = _b.sent();
                    return [2 /*return*/, thunkAPI.rejectWithValue(e_8)];
                case 3: return [2 /*return*/];
            }
        });
    });
});
var strategyCardSlice = createSlice({
    name: 'strategyCardSlice',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        builder.addCase(getStrategyCardData.pending, function (state) {
            state.strategyCardDataStatus = START_FETCH_STATUS;
        });
        builder.addCase(getStrategyCardData.rejected, function (state, _a) {
            var payload = _a.payload;
            state.strategyCardDataStatus.error = payload;
            state.strategyCardDataStatus.isLoading = false;
        });
        builder.addCase(getStrategyCardData.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.strategyCardDataStatus.isLoading = false;
            state.strategyCardData = payload;
        });
        builder.addCase(updateStrategyCardData.pending, function (state) {
            state.updateStrategyCardDataStatus = START_FETCH_STATUS;
        });
        builder.addCase(updateStrategyCardData.rejected, function (state, _a) {
            var payload = _a.payload;
            state.updateStrategyCardDataStatus.error = payload;
            state.updateStrategyCardDataStatus.isLoading = false;
        });
        builder.addCase(updateStrategyCardData.fulfilled, function (state) {
            state.updateStrategyCardDataStatus.isLoading = false;
        });
        builder.addCase(getStrategyTickers.pending, function (state) {
            state.strategyTickersStatus = START_FETCH_STATUS;
        });
        builder.addCase(getStrategyTickers.rejected, function (state, _a) {
            var payload = _a.payload;
            state.strategyTickersStatus.error = payload;
            state.strategyTickersStatus.isLoading = false;
        });
        builder.addCase(getStrategyTickers.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.strategyTickersStatus.isLoading = false;
            state.strategyTickers = payload;
        });
        builder.addCase(getStrategyRecentDeals.pending, function (state) {
            state.strategyRecentDealsStatus = START_FETCH_STATUS;
        });
        builder.addCase(getStrategyRecentDeals.rejected, function (state, _a) {
            var payload = _a.payload;
            state.strategyRecentDealsStatus.error = payload;
            state.strategyRecentDealsStatus.isLoading = false;
        });
        builder.addCase(getStrategyRecentDeals.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.strategyRecentDealsStatus.isLoading = false;
            state.strategyRecentDeals = payload;
        });
        builder.addCase(addFavoriteStrategy.fulfilled, function (state, _a) {
            var payload = _a.payload;
        });
        builder.addCase(removeFavoriteStrategy.fulfilled, function (state, _a) {
            var payload = _a.payload;
        });
        builder.addCase(getStrategyComments.pending, function (state) {
            state.strategyCommentsStatus = START_FETCH_STATUS;
        });
        builder.addCase(getStrategyComments.rejected, function (state, _a) {
            var payload = _a.payload;
            state.strategyCommentsStatus.error = payload;
            state.strategyCommentsStatus.isLoading = false;
        });
        builder.addCase(getStrategyComments.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.strategyCommentsStatus.isLoading = false;
            state.strategyComments = payload;
        });
        builder.addCase(addStrategyComment.pending, function (state) {
            state.addStrategyCommentStatus = START_FETCH_STATUS;
        });
        builder.addCase(addStrategyComment.rejected, function (state, _a) {
            var payload = _a.payload;
            state.addStrategyCommentStatus.error = payload;
            state.addStrategyCommentStatus.isLoading = false;
        });
        builder.addCase(addStrategyComment.fulfilled, function (state) {
            state.addStrategyCommentStatus.isLoading = false;
        });
    },
});
export default strategyCardSlice.reducer;
export { getStrategyCardData, addFavoriteStrategy, removeFavoriteStrategy, getStrategyTickers, getStrategyComments, addStrategyComment, getStrategyRecentDeals, updateStrategyCardData, };
