export var socketMiddleware = function (socket) {
    return function (_a) {
        var dispatch = _a.dispatch;
        return function (next) {
            return function (action) {
                var type = action.type, payload = action.payload;
                switch (type) {
                    case 'socket/connect': {
                        var url = payload.endpoint;
                        socket.connect(url);
                        socket.on('open', function () {
                            console.log('WebSocket successfully opened');
                            dispatch({ type: 'socket/connected', payload: { message: 'Соединение установлено' } });
                        });
                        socket.on('message', function (event) {
                            var data = event.data;
                            dispatch({ type: 'socket/messageReceived', payload: data });
                        });
                        socket.on('close', function () {
                            console.log('WebSocket successfully closed');
                            dispatch({ type: 'socket/disconnected' });
                        });
                        break;
                    }
                    case 'socket/disconnect':
                        socket.disconnect();
                        break;
                    default:
                        break;
                }
                return next(action);
            };
        };
    };
};
