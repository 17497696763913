var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from './styles';
import { ReactComponent as Tip } from '@/shared/assets/icons/tips.svg';
import { ReactComponent as CloseIcon } from '@/shared/assets/icons/closeTip.svg';
import { Space } from '@/shared/ui';
import { useTypedSelector } from '@/shared/hooks/useTypesSelector/useTypesSelector';
import { useState } from 'react';
var Hint = function (_a) {
    var title = _a.title, text = _a.text, arrowPosition = _a.arrowPosition, arrowAlign = _a.arrowAlign, posX = _a.posX, posY = _a.posY, children = _a.children, css = _a.css, _b = _a.close, close = _b === void 0 ? true : _b;
    var currentTheme = useTypedSelector(function (state) { return state.themeReducer.theme; });
    var _c = useState(false), isTipClosed = _c[0], setIsTipClosed = _c[1];
    var handleCloseTip = function () {
        setIsTipClosed(true);
    };
    return (_jsxs(S.HintWrapper, __assign({ css: css, style: { left: posX, top: posY, display: isTipClosed ? 'none' : '' } }, { children: [_jsx(S.Arrow, { position: arrowPosition, align: arrowAlign }), _jsxs(Space, __assign({ content: "between", css: { width: '100%' } }, { children: [_jsxs(Space, __assign({ size: "default", align: "center" }, { children: [_jsx(Tip, {}), _jsx(S.HintTitle, __assign({ type: "header" }, { children: title }))] })), close && (_jsx("div", __assign({ onClick: handleCloseTip, style: { cursor: 'pointer' } }, { children: _jsx(CloseIcon, { fill: currentTheme === 'dark' ? 'white' : '#1D2023' }) })))] })), _jsx(S.HintContentText, __assign({ type: "smallText" }, { children: text })), children] })));
};
export { Hint };
