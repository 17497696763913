import { styled } from '@stitches/react';
import { Text } from '@/shared/ui/Text/style';
export var HintWrapper = styled('div', {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    top: 100,
    alignItems: 'start',
    width: '389px',
    height: '96px',
    borderRadius: '8px',
    padding: '12px',
    boxSizing: 'border-box',
    backgroundColor: '$bgMain',
    boxShadow: '$modalsShadow',
    cursor: 'default',
});
export var Arrow = styled('div', {
    position: 'absolute',
    width: 1,
    height: 0,
    borderStyle: 'solid',
    variants: {
        position: {
            top: {
                borderWidth: '0 10px 10px 10px',
                borderColor: 'transparent transparent $bgMain transparent',
                top: '-9px',
                left: '50%',
                transform: 'translateX(-50%)',
            },
            bottom: {
                borderWidth: '10px 10px 0 10px',
                borderColor: '$bgMain transparent transparent transparent',
                bottom: '-10px',
                left: '50%',
                transform: 'translateX(-50%)',
            },
            left: {
                borderWidth: '10px 10px 10px 0',
                borderColor: 'transparent $bgMain transparent transparent',
                left: '-10px',
                top: '50%',
                transform: 'translateY(-50%)',
            },
            right: {
                borderWidth: '10px 0 10px 10px',
                borderColor: 'transparent transparent transparent $bgMain',
                right: '-10px',
                top: '50%',
                transform: 'translateY(-50%)',
            },
        },
        align: {
            start: {
                left: '10px',
                transform: 'none',
            },
            center: {
                left: '50%',
                transform: 'translateX(-50%)',
            },
            end: {
                left: 'calc(100% - 31px)',
                transform: 'none',
            },
        },
    },
    borderRadius: '3px',
});
export var HintTitle = styled(Text, {
    color: '$textLinkDefault !important',
    fontFamily: '$montserrat',
});
export var HintContentText = styled(Text, {
    fontFamily: '$montserrat',
    textAlign: 'start',
    paddingLeft: '32px',
    color: '$primaryText !important',
});
