var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from './style';
import { BorderType, InputType, LabelType } from './type';
import React, { memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { ReactComponent as Cross } from '@/shared/assets/icons/Cross.svg';
import { Icon, Text, Tooltip, Space } from '@/shared/ui';
import { createPortal } from 'react-dom';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { useTypedSelector } from '@/shared/hooks/useTypesSelector/useTypesSelector';
import { capitalizeFirstLetter } from '@/shared/utils/capitalizeFirstLetter';
// import { FunctionDescriptionPanel } from '@/widgets/ui/FunctionDescriptionPanel/FunctionDescriptionPanel';
import { setFuncDescriptionHoveredData, setFuncDescriptionLimitPriceHoveredData, } from '@/entities/filterExpression/model/filterExpressionSlice';
export { Root, Trigger, Portal, } from '@radix-ui/react-dialog';
var getOS = function () {
    var userAgent = window.navigator.userAgent.toLowerCase();
    if (userAgent.includes('mac'))
        return '⌘';
    if (userAgent.includes('win'))
        return 'Alt';
    return 'Unknown';
};
var SelectWithSearch = memo(function (_a) {
    var id = _a.id, _b = _a.size, size = _b === void 0 ? 'large' : _b, error = _a.error, value = _a.value, onChange = _a.onChange, onClick = _a.onClick, options = _a.options, placeholder = _a.placeholder, onClickSetting = _a.onClickSetting, _c = _a.minWidth, minWidth = _c === void 0 ? 260 : _c, _d = _a.isLeft, isLeft = _d === void 0 ? false : _d, _e = _a.isSetting, isSetting = _e === void 0 ? false : _e, _f = _a.isHintValue, isHintValue = _f === void 0 ? false : _f, css = _a.css, cssDropdown = _a.cssDropdown, _g = _a.type, type = _g === void 0 ? 'withSearch' : _g, disableClearIcon = _a.disableClearIcon, _h = _a.doClear, doClear = _h === void 0 ? false : _h, paddingLeft = _a.paddingLeft, paddingRight = _a.paddingRight, icon = _a.icon, showText = _a.showText, disabled = _a.disabled, selectRef = _a.selectRef, autoFocus = _a.autoFocus, _j = _a.arrow, arrow = _j === void 0 ? true : _j, inputCss = _a.inputCss, settingValue = _a.settingValue, tooltip = _a.tooltip, _k = _a.isHotkey, isHotkey = _k === void 0 ? false : _k;
    var dispatch = useTypedDispatch();
    var ref = useRef(null);
    var searchInputRef = useRef(null);
    var refDropdown = useRef(null);
    var _l = useState(false), isOpen = _l[0], setIsOpen = _l[1];
    var _m = useState(null), highlightedIndex = _m[0], setHighlightedIndex = _m[1];
    var _o = useState(null), activeIndex = _o[0], setActiveIndex = _o[1];
    var _p = useState(''), inputValue = _p[0], setInputValue = _p[1];
    var _q = useState(), selectedOption = _q[0], setSelectedOption = _q[1];
    var _r = useState([]), selectOptions = _r[0], setSelectOptions = _r[1];
    var _s = useState({ top: 0, left: 0, width: 0 }), position = _s[0], setPosition = _s[1];
    var currentTheme = useTypedSelector(function (state) { return state.themeReducer.theme; });
    var isOpenLimitPriceModal = useTypedSelector(function (state) { return state.filterExpressionReducer.isOpenLimitPriceModal; });
    var _t = useState(null), modalContainer = _t[0], setModalContainer = _t[1];
    useEffect(function () {
        setSelectOptions(options);
    }, [options]);
    var handlePosition = useCallback(function () {
        var _a, _b;
        if (ref.current) {
            var element = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
            var input = (_b = ref.current) === null || _b === void 0 ? void 0 : _b.getBoundingClientRect();
            if (isLeft) {
                setPosition({
                    left: element.x + window.scrollX - (element.width > minWidth ? element.width : minWidth - input.width),
                    top: element.y + window.scrollY + (size === 'small' ? 46 : size === 'medium' ? 58 : 70),
                    width: element.width > minWidth ? element.width : minWidth,
                });
            }
            else {
                setPosition({
                    left: element.x + window.scrollX,
                    top: element.y + window.scrollY + (size === 'small' ? 46 : size === 'medium' ? 58 : 70),
                    width: element.width > minWidth ? element.width : minWidth,
                });
            }
        }
    }, [minWidth]);
    useLayoutEffect(function () {
        if (isOpen) {
            handlePosition();
        }
    }, [isOpen, handlePosition]);
    useEffect(function () {
        if (!(options === null || options === void 0 ? void 0 : options.length)) {
            setInputValue('');
            setSelectedOption({ id: '', text: '', value: '' });
        }
    }, [value]);
    useEffect(function () {
        if (options === null || options === void 0 ? void 0 : options.length) {
            for (var _i = 0, options_1 = options; _i < options_1.length; _i++) {
                var item = options_1[_i];
                if (item.text === value.text) {
                    if (!showText) {
                        setInputValue(item.value);
                    }
                    else {
                        setInputValue(item.text);
                    }
                    setSelectedOption(item);
                    return;
                }
            }
            setInputValue('');
            setSelectedOption({ id: '', text: '', value: '' });
        }
    }, [value, options, isHintValue]);
    useEffect(function () {
        if (!doClear) {
            if (!isOpen) {
                if (((selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.id) && !inputValue)
                    || (!isHintValue && (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.text) && (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.text) !== inputValue)
                    || (isHintValue && (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.value) && (selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.value) !== inputValue)) {
                    if (isHintValue) {
                        setInputValue(selectedOption.text);
                    }
                    else {
                        setInputValue(selectedOption.text);
                    }
                }
            }
        }
        else {
            if (!isOpen) {
                if (!inputValue && !(selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.id)) {
                    setInputValue('');
                    setSelectedOption({ id: '', text: '', value: '' });
                }
                else if (selectedOption) {
                    setInputValue(isHintValue ? selectedOption.value : selectedOption.text);
                }
            }
        }
    }, [isOpen, value, inputValue, selectedOption, isHintValue]);
    var handleClickOutside = useCallback(function (event) {
        var _a, _b;
        event.stopPropagation();
        if (!((_a = ref === null || ref === void 0 ? void 0 : ref.current) === null || _a === void 0 ? void 0 : _a.contains(event.target))
            && !((_b = refDropdown === null || refDropdown === void 0 ? void 0 : refDropdown.current) === null || _b === void 0 ? void 0 : _b.contains(event.target))) {
            setIsOpen(false);
        }
    }, [selectOptions]);
    useEffect(function () {
        document.addEventListener('mousedown', handleClickOutside);
        return function () {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);
    var changeValue = function (val, idx) {
        onChange(val, idx);
        setIsOpen(false);
        if (!doClear) {
            if (selectOptions.length !== options.length) {
                setSelectOptions(options);
            }
        }
        else {
            setSelectedOption(val);
            setInputValue(isHintValue ? String(val.value) : val.text);
        }
    };
    var handleClearField = function () {
        setInputValue('');
        setSelectedOption({ id: '', text: '', value: '' });
        onChange({ id: '', text: '', value: '' }, 'value');
    };
    var onSearch = function (e) {
        if (disabled)
            return;
        if (!doClear) {
            if (!isOpen)
                return;
            var query = e.target.value;
            var queryLowCase_1 = e.target.value.toLowerCase();
            var filteredOptions = options.filter(function (option) { return option.text.toLowerCase().startsWith(queryLowCase_1); });
            setInputValue(query);
            setSelectOptions(filteredOptions);
        }
        else {
            if (!isOpen)
                return;
            var query = e.target.value;
            var queryLowCase_2 = query.toLowerCase();
            var filteredOptions = options.filter(function (option) { return option.text.toLowerCase().startsWith(queryLowCase_2); });
            setInputValue(query);
            setSelectOptions(filteredOptions);
            if (query === '') {
                setSelectedOption({ id: '', text: '', value: '' });
            }
        }
    };
    useEffect(function () {
        if (isOpen) {
            var initIndex = selectOptions.findIndex(function (i) { return i.value === (value === null || value === void 0 ? void 0 : value.value); });
            setHighlightedIndex(initIndex);
        }
    }, [isOpen, selectOptions, value]);
    var scrollToActiveOption = function (index) {
        var dropdown = refDropdown.current;
        var optionElements = dropdown === null || dropdown === void 0 ? void 0 : dropdown.querySelectorAll('.dropdown-option');
        if (!dropdown || !optionElements || !optionElements[index])
            return;
        var activeOption = optionElements[index];
        var dropdownRect = dropdown.getBoundingClientRect();
        var optionRect = activeOption.getBoundingClientRect();
        if (optionRect.bottom > dropdownRect.bottom) {
            dropdown.scrollTop += optionRect.bottom - dropdownRect.bottom;
        }
        else if (optionRect.top < dropdownRect.top) {
            dropdown.scrollTop -= dropdownRect.top - optionRect.top;
        }
    };
    useEffect(function () {
        if ((value === null || value === void 0 ? void 0 : value.value) && refDropdown.current) {
            var selectedOptionIndex = selectOptions.findIndex(function (option) { return option.value === value.value; });
            if (selectedOptionIndex !== -1) {
                setActiveIndex(selectedOptionIndex);
                scrollToActiveOption(selectedOptionIndex);
            }
        }
    }, [value, selectOptions, isOpen]);
    var handleKeyDown = function (event) {
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            if (!isOpen) {
                setIsOpen(true);
                return;
            }
            setHighlightedIndex(function (prevIndex) {
                var nextIndex = prevIndex === null || prevIndex >= selectOptions.length - 1
                    ? 0
                    : prevIndex + 1;
                scrollToActiveOption(nextIndex);
                return nextIndex;
            });
            setActiveIndex(function (prevIndex) {
                var nextIndex = prevIndex === null || prevIndex >= selectOptions.length - 1
                    ? 0
                    : prevIndex + 1;
                scrollToActiveOption(nextIndex);
                return nextIndex;
            });
        }
        if (event.key === 'ArrowUp') {
            event.preventDefault();
            if (!isOpen) {
                setIsOpen(true);
                return;
            }
            setHighlightedIndex(function (prevIndex) {
                var nextIndex = prevIndex === null || prevIndex <= 0
                    ? selectOptions.length - 1
                    : prevIndex - 1;
                scrollToActiveOption(nextIndex);
                return nextIndex;
            });
            setActiveIndex(function (prevIndex) {
                var nextIndex = prevIndex === null || prevIndex <= 0
                    ? selectOptions.length - 1
                    : prevIndex - 1;
                scrollToActiveOption(nextIndex);
                return nextIndex;
            });
        }
        if (event.key === 'Enter' && highlightedIndex !== null && highlightedIndex !== -1) {
            event.preventDefault();
            var selectedItem = selectOptions[highlightedIndex];
            changeValue(selectedItem, id);
            setIsOpen(false);
        }
        else if (event.key === 'Enter') {
            setIsOpen(false);
        }
        if (event.key === 'Tab') {
            setIsOpen(false);
            if ((value === null || value === void 0 ? void 0 : value.value) && isSetting) {
                event.preventDefault();
                onClickSetting === null || onClickSetting === void 0 ? void 0 : onClickSetting();
            }
        }
    };
    var openDropdown = function () {
        setIsOpen(true);
        if ((refDropdown === null || refDropdown === void 0 ? void 0 : refDropdown.current) && activeIndex !== null) {
            scrollToActiveOption(activeIndex);
        }
    };
    useEffect(function () {
        var mainContainer = document.querySelector('.main-container');
        setModalContainer(mainContainer);
    }, []);
    var setRefs = function (el) {
        ref.current = el;
        if (selectRef) {
            if (typeof selectRef === 'function') {
                selectRef(el);
            }
            else if (selectRef.hasOwnProperty('current')) {
                selectRef.current = el;
            }
        }
    };
    var setSettingsValue = function (value, selectType) {
        switch (selectType) {
            case 'Function':
                return (value === null || value === void 0 ? void 0 : value.function_name) ? capitalizeFirstLetter(value === null || value === void 0 ? void 0 : value.function_name) : selectType;
            case 'Attribute':
                return (value === null || value === void 0 ? void 0 : value.attribute_name) ? capitalizeFirstLetter(value === null || value === void 0 ? void 0 : value.attribute_name) : selectType;
            case 'Custom value':
                return (value === null || value === void 0 ? void 0 : value.value) ? value === null || value === void 0 ? void 0 : value.value : selectType;
            default:
                return selectType;
        }
    };
    var setPlaceholderView = function (value, selectType) {
        switch (selectType) {
            case 'Function':
                return (value === null || value === void 0 ? void 0 : value.function_name) ? true : false;
            case 'Attribute':
                return (value === null || value === void 0 ? void 0 : value.attribute_name) ? true : false;
            case 'Custom value':
                return (value === null || value === void 0 ? void 0 : value.value) ? true : false;
            default:
                return true;
        }
    };
    useEffect(function () {
        var _a;
        if (isOpen) {
            (_a = searchInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [isOpen]);
    return (_jsxs(S.SelectWrapper, __assign({ ref: setRefs, css: error ? { marginBottom: '24px' } : {}, onClick: onClick, disabled: disabled, onKeyDown: handleKeyDown, onFocus: function () { return setIsOpen(true); }, tabIndex: disabled ? -1 : 0 }, { children: [_jsxs(S.Select, __assign({ css: css, onClick: openDropdown, border: error ? BorderType.ERROR : BorderType.DEFAULT, size: size }, { children: [_jsxs("div", __assign({ style: {
                            flexGrow: 1,
                            display: size === 'small' ? 'flex' : 'block',
                            alignItems: 'center',
                            gap: size === 'small' ? placeholder ? '12px' : '0px' : '0px',
                        } }, { children: [_jsxs("div", __assign({ style: { display: 'flex', alignItems: 'center', gap: '4px' } }, { children: [icon && (_jsx(S.Icon, { children: _jsx(Icon, { IconItem: icon, fill: '#64748B' }) })), placeholder && setPlaceholderView(settingValue, inputValue) && (_jsx(S.SelectLabel, __assign({ htmlFor: id, size: size, type: inputValue || isOpen ? LabelType.LABEL : LabelType.PLACEHOLDER, disabled: disabled }, { children: settingValue ? inputValue : placeholder })))] })), type === 'onlySelect' && inputValue && (_jsx(S.SelectInput, { id: id, autoComplete: 'off', readOnly: true, value: setSettingsValue(settingValue, inputValue), type: 'text', size: size, css: __assign({}, inputCss), tabIndex: 0, autoFocus: autoFocus })), type === 'withSearch' && (inputValue || isOpen) && (_jsx(S.SelectInput, { ref: searchInputRef, id: id, autoComplete: 'off', onChange: onSearch, value: inputValue, type: 'text', size: size, css: __assign(__assign({}, inputCss), { paddingLeft: paddingLeft + 'px !important', paddingRight: paddingRight + 'px !important' }), padding: inputValue ? InputType.SMALL : InputType.DEFAULT, tabIndex: 0, autoFocus: autoFocus }))] })), inputValue && !disableClearIcon && (_jsx(S.CrossIcon, __assign({ onClick: function (e) {
                            e.stopPropagation();
                            handleClearField();
                            setIsOpen(false);
                        } }, { children: _jsx(Cross, { width: 24, height: 24, fill: currentTheme === 'dark' ? 'white' : 'black' }) }))), (tooltip || arrow) && (_jsxs(Space, __assign({ size: 'small', align: 'center' }, { children: [tooltip && (_jsx(Tooltip, __assign({ content: tooltip, css: { width: '300px' } }, { children: _jsx("div", __assign({ style: { display: 'flex', alignItems: 'center' } }, { children: _jsx(S.HelpIcon, {}) })) }))), arrow && (_jsx(S.SelectIcon, __assign({ "data-state-open": isOpen, disabled: disabled }, { children: _jsx(S.ArrowIcon, { disabled: disabled }) })))] })))] })), isOpen && !disabled && modalContainer && createPortal(_jsxs(S.SelectDropdown, __assign({ ref: refDropdown, style: position, "data-state-open": isOpen, "data-state-left-position": isLeft, tabIndex: 0, css: cssDropdown }, { children: [_jsx(S.SelectDropdownOptions, { children: selectOptions.map(function (item, index) { return (_jsxs(S.SelectDropdownOption, __assign({ size: size, onClick: function () {
                                if (isHotkey) {
                                    if (isOpenLimitPriceModal) {
                                        dispatch(setFuncDescriptionLimitPriceHoveredData(null));
                                    }
                                    else {
                                        dispatch(setFuncDescriptionHoveredData(null));
                                    }
                                }
                                changeValue(item, id);
                            }, "data-state-select": item.text === value.text, "data-highlighted": index === highlightedIndex, className: "dropdown-option ".concat(index === activeIndex ? 'active' : ''), css: index === highlightedIndex
                                ? { backgroundColor: '$controlsBgSecondaryDefault' }
                                : {}, onMouseEnter: function () {
                                if (isHotkey) {
                                    if (isOpenLimitPriceModal) {
                                        dispatch(setFuncDescriptionLimitPriceHoveredData({ name: capitalizeFirstLetter(item.text), description: item.id }));
                                    }
                                    else {
                                        dispatch(setFuncDescriptionHoveredData({ name: capitalizeFirstLetter(item.text), description: item.id }));
                                    }
                                }
                            }, onMouseLeave: function () {
                                if (isHotkey) {
                                    if (isOpenLimitPriceModal) {
                                        dispatch(setFuncDescriptionLimitPriceHoveredData(null));
                                    }
                                    else {
                                        dispatch(setFuncDescriptionHoveredData(null));
                                    }
                                }
                            } }, { children: [_jsx("p", { children: item.text }), isHintValue && (_jsx(S.SelectDropdownOptionHint, { children: item.value }))] }), item.text)); }) }), isHotkey && (_jsxs(S.HotkeyContainer, { children: [_jsx(Text, __assign({ type: 'smallText' }, { children: "Show/hide function description" })), _jsx(Text, __assign({ type: 'smallText', css: {
                                    fontWeight: 500,
                                    lineHeight: '20px',
                                    padding: '1px 5px',
                                    border: '1px solid $controlsStrokeDefault',
                                    borderRadius: '4px',
                                } }, { children: "".concat(getOS(), " + E") }))] }))] })), modalContainer), error && (_jsx(Text, __assign({ type: 'hint', css: { position: 'absolute', color: '$error', margin: '4px 0 0 16px' } }, { children: error })))] })));
});
export { SelectWithSearch };
