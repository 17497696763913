var _a;
import { createStitches, globalCss } from '@stitches/react';
export var getCssText = (_a = createStitches({
    theme: {
        colors: {
            //Constants
            alwaysWhite: '#FFFFFF',
            alwaysBlack: '#1D2023',
            // Background
            bgMain: '#FFFFFF',
            bgSecondlayer: '#FFFFFF',
            bgTransparent: '#FFFFFFCC',
            bgTertiary: '#F7F8F9',
            bgAccent: '#E6F6F8',
            bgInputWhite: '#FFFFFF',
            bgInput: '#F3F5F6',
            bgSecondary: '#F3F5F6',
            bgAttentionLight: '#FFFADE',
            bgWidgetArea: '#E6F6F880',
            bgError: '#FFE1EB',
            purpleBgDefault: '#F6ECFF',
            blueBgDefault: '#D9F1FC',
            greenBgDefault: '#E8F3E8',
            // Background Controls
            controlsBgDefault: '#FFFFFF',
            controlsBgPrimaryDefault: '#242B31',
            controlsBgPrimaryHover: '#323A46',
            controlsBgPrimaryDisabled: '#323A46',
            controlsBgSecondaryDefault: '#E7EAEE',
            controlsBgSecondaryPressed: '#B8C0CC',
            controlsBgSecondaryHover: '#D0D5DD',
            controlsBgSecondaryDisabled: '#E7EAEE',
            controlsBgAccentDefault: '#00A5BC',
            controlsBgAccentHover: '#00809C',
            controlsBgAccentPressed: '#04607D',
            controlsBgGhostAccentHover: '#E6F6F8',
            controlsBgGhostAccentPressed: '#B3E4EB',
            controlsBgAccentDisabled: '#B3E4EB',
            //Text
            primaryText: '#1D2023',
            textSecondary: '#64748B',
            textSecondaryInverted: '#B8C0CC',
            textTertiaryDisabled: '#A0ABBB',
            textLinkDefault: '#00A5BC',
            textLinkDisabled: '#66C9D7',
            textLinkHover: '#00809C',
            textInvertedWhite: '#FFFFFF',
            textInvertedBlack: '#1D2023',
            textError: '#D32E5F',
            textSuccess: '#47C545',
            textAttention: '#F9D936',
            purpleText: '#A31FE1',
            blueText: '#2485E8',
            orangeText: '#E1701F',
            greenText: '#297C27',
            //Divider
            divider: '#E7EAEE',
            //Controls Stroke
            controlsStrokeDefault: '#D0D5DD',
            controlsStrokeHover: '#B8C0CC',
            controlsStrokeFocus: '#64748B',
            controlsStrokeDisabled: '#E7EAEE',
            controlsStrokeAttention: '#F9D936',
            controlsStrokeAccent: '#00A5BC',
            controlsStrokePrimary: '#1D2023',
            controlsStrokeError: '#D32E5F',
            //Logs widget
            logsSuccess: '#1D2023',
            logsError: '#1D2023',
            // News Modal
            overlayNewsModal: '#F3F5F6CC',
            // Graphs
            graphPink: '#96346C',
            graphYellow: '#F3D381',
            graphBlue: '#A2C5EB',
            graphPurple: '#49185C',
            graphOrange: '#E9865B',
            graphBluemarine: '#6FC1C1',
            graphDarkBlue: '#5076C8',
            graphBrightPink: '#EB91F2',
            graphGreen: '#225637',
            graphBrightGreen: '#ADC355',
            // Infographics
            orangeBgDefault: '#FFF5F1',
            // Landing colors
            landingVideoBorder: 'linear-gradient(150deg, rgba(30, 30, 226, 0.7), rgba(0, 140, 200, 0.8), rgba(0, 6, 226, 0.4))',
            landingBgMain: '#FFFFFF',
            landingBgSecondary: '#F3F5F6',
            landingBgInverted: '#1D2023',
            landingTableHighlightedRow: 'rgba(255, 255, 255, 0.6)',
            landingTransparentBlockBg: 'rgba(255, 255, 255, 0.6)',
            white: '#FFF',
            black: '#000',
            buttonDark: '#242B31',
            defaultButton: '#E7EAEE',
            disabledButton: '#323A46',
            header: '#242B31',
            lightTextDefault: '#00A5BC',
            mainBackground: '#F3F5F6',
            labelPubBg: '#EBD9FC',
            labelDraftBg: '#E7EAEE',
            labelLocalBg: '#D9F1FC',
            labelPub: '#A31FE1',
            labelDraft: '#323A46',
            labelLocal: '#2485E8',
            inputBorderError: '#D32E5F',
            inputBorderHover: '#B8C0CC',
            inputBorderFocus: '#64748B',
            inputBg: '#F3F5F6',
            InterfaceChipSelected: '#323A46',
            InterfaceChipNotSelected: '#0000ffff',
            newsInfo: '#64748B',
            textDisabled: '#A0ABBB',
            colorTabItem: '#A0ABBB',
            ghostAccentHover: '#E6F6F8',
            controlsStroke: '#D0D5DD',
            linkDefault: '#00A5BC',
            accentHover: '#00809C',
            primaryHover: '#D0D5DD',
            darkText: '#64748B',
            hasSubscriptionBg: '#E6F6F8',
            hasSubscriptionStroke: '#00A5BC',
            green: '#47C545',
            aquamarine: '#00A5BC',
            darknessAquamarine: '#B3E4EB',
            lightAquamarine: '#E6F6F8',
            grayBackground: '#F7F8F9',
            yellow: '#F9D936',
            lightYellow: '#FFFADE',
            brightPink: '#D32E5F',
            palePink: '#FFE1EB',
            darkBlue: '#5076C8',
            blue: '#A2C5EB',
            purple: '#49185C',
            brightPurple: '#EB91F2',
            error: '#D32E5F',
            lightControlsAccent: '#00809C',
        },
        fonts: {
            montserrat: 'Montserrat, sans-serif',
            ubuntu: 'Ubuntu Mono, sans-serif',
            afacad: 'Afacad, sans-serif',
        },
        shadows: {
            modalsShadow: '1px 1px 20px 1px rgba(0, 0, 0, 0.10)',
            additionalShadow: '1px 1px 20px 1px rgba(169, 169, 169, 0.05)',
            noHasSettingsShadow: 'inset 0 0 0 1px #D32E5F',
            isAvailableConnectionShadow: 'inset 0 0 0 2px #00A5BC',
            isNotAvailableConnectionShadow: 'inset 0 0 0 2px #F83972',
        },
    },
    media: {
        mobile: 'only screen and (max-width: 767px)',
        tablet: 'only screen and (min-width: 768px) and (max-width: 1279px)',
        desktop: 'only screen and (min-width: 1280px)',
    },
}), _a.getCssText), styled = _a.styled, css = _a.css, createTheme = _a.createTheme;
export var darkTheme = createTheme({
    colors: {
        //Constants
        alwaysWhite: '#FFFFFF',
        alwaysBlack: '#1D2023',
        // Background
        bgMain: '#242B31',
        bgSecondlayer: '#323A46',
        bgTransparent: '#242B31CC',
        bgTertiary: '#323A46',
        bgAccent: '#023D50',
        bgInput: '#434B58',
        bgInputWhite: '#434B58',
        bgSecondary: '#242B31',
        bgAttentionLight: '#6C5927',
        bgWidgetArea: '#023D5080',
        bgError: '#623241',
        purpleBgDefault: '#5A2772',
        blueBgDefault: '#0E4883',
        greenBgDefault: '#0B460A',
        // Background Controls
        controlsBgDefault: '#242B31',
        controlsBgPrimaryDefault: '#FFFFFF',
        controlsBgPrimaryHover: '#D0D5DD',
        controlsBgPrimaryDisabled: '#FFFFFF',
        controlsBgSecondaryDefault: '#4B5768',
        controlsBgSecondaryHover: '#323A46',
        controlsBgSecondaryPressed: '#1D2023',
        controlsBgSecondaryDisabled: '#323A46',
        controlsBgAccentDefault: '#00A5BC',
        controlsBgAccentHover: '#00809C',
        controlsBgAccentPressed: '#04607D',
        controlsBgGhostAccentHover: '#07485C',
        controlsBgGhostAccentPressed: '#023545',
        controlsBgAccentDisabled: '#04607D',
        //Text
        primaryText: '#FFFFFF',
        textSecondary: '#8794A8',
        textSecondaryInverted: '#64748B',
        textTertiaryDisabled: '#636B77',
        textLinkDefault: '#00A5BC',
        textLinkDisabled: '#04607D',
        textLinkHover: '#00809C',
        textInvertedWhite: '#1D2023',
        textInvertedBlack: '#FFFFFF',
        textError: '#F83972',
        textSuccess: '#47C545',
        textAttention: '#F9D936',
        purpleText: '#D086F3',
        blueText: '#479FFA',
        orangeText: '#E1701F',
        greenText: '#297C27',
        //Divider
        divider: '#414852',
        //Controls Stroke
        controlsStrokeDefault: '#555D67',
        controlsStrokeHover: '#64748B',
        controlsStrokeFocus: '#A0ABBB',
        controlsStrokeDisabled: '#31373E',
        controlsStrokeAttention: '#F9D936',
        controlsStrokeAccent: '#00A5BC',
        controlsStrokePrimary: '#FFFFFF',
        controlsStrokeError: '#F83972',
        //Logs widget
        logsSuccess: '#47C545',
        logsError: '#D32E5F',
        // News Modal
        overlayNewsModal: '#1D2023CC',
        // Graphs
        graphPink: '#96346C',
        graphYellow: '#F3D381',
        graphBlue: '#A2C5EB',
        graphPurple: '#49185C',
        graphOrange: '#E9865B',
        graphBluemarine: '#6FC1C1',
        graphDarkBlue: '#5076C8',
        graphBrightPink: '#EB91F2',
        graphGreen: '#225637',
        graphBrightGreen: '#ADC355',
        // Infographics
        orangeBgDefault: '#653411',
        // Landing colors
        landingVideoBorder: 'linear-gradient(120deg, rgba(70, 80, 226, 0.7), rgba(0, 165, 187, 1), rgba(190, 0, 226, 0.6))',
        landingBgMain: '#1F2225',
        landingBgSecondary: '#242B31',
        landingBgInverted: '#FFFFFF',
        landingTableHighlightedRow: 'rgba(255, 255, 255, 0.1)',
        landingTransparentBlockBg: 'rgba(255, 255, 255, 0.05)',
        white: '#242B31',
        black: '#000',
        buttonDark: '#242B31',
        defaultButton: '#E7EAEE',
        disabledButton: '#FFF',
        header: '#FFFFFF',
        lightTextDefault: '#00A5BC',
        mainBackground: '#242B31',
        labelPubBg: '#EBD9FC',
        labelDraftBg: '#E7EAEE',
        labelLocalBg: '#D9F1FC',
        labelPub: '#A31FE1',
        labelDraft: '#323A46',
        labelLocal: '#2485E8',
        inputBorderError: '#D32E5F',
        inputBorderHover: '#B8C0CC',
        inputBorderFocus: '#64748B',
        inputBg: '#F3F5F6',
        InterfaceChipSelected: '#323A46',
        InterfaceChipNotSelected: '#0000ffff',
        newsInfo: '#64748B',
        textDisabled: '#A0ABBB',
        colorTabItem: '#A0ABBB',
        ghostAccentHover: '#E6F6F8',
        controlsStroke: '#D0D5DD',
        linkDefault: '#00A5BC',
        accentHover: '#00809C',
        primaryHover: '#D0D5DD',
        darkText: '#64748B',
        hasSubscriptionBg: '#E6F6F8',
        hasSubscriptionStroke: '#00A5BC',
        green: '#47C545',
        aquamarine: '#00A5BC',
        darknessAquamarine: '#B3E4EB',
        lightAquamarine: '#E6F6F8',
        grayBackground: '#F7F8F9',
        yellow: '#F9D936',
        lightYellow: '#FFFADE',
        brightPink: '#D32E5F',
        palePink: '#FFE1EB',
        darkBlue: '#5076C8',
        blue: '#A2C5EB',
        purple: '#49185C',
        brightPurple: '#EB91F2',
        error: '#D32E5F',
        lightControlsAccent: '#00809C',
    },
    shadows: {
        modalsShadow: '1px 1px 20px 1px rgba(0, 0, 0, 0.40)',
        additionalShadow: '1px 1px 20px 1px rgba(0, 0, 0, 0.05)',
        noHasSettingsShadow: 'inset 0 0 0 1px #F83972',
        isAvailableConnectionShadow: 'inset 0 0 0 2px #00A5BC',
        isNotAvailableConnectionShadow: 'inset 0 0 0 2px #F83972',
    },
});
var setupStyles = globalCss({
    html: {
        height: '100%',
    },
    body: {
        margin: 0,
        padding: 0,
        fontFamily: '$montserrat',
        background: '$bgMain',
    },
    'h1, h2, h3, h4, h5, h6, p': {
        margin: 0,
    },
});
export var setupGlobalStyles = function () {
    setupStyles();
};
export var MainContainer = styled('main', {
    background: '$bgMain',
});
