var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    filterExpressionId: 0,
    isOpenLimitPriceModal: false,
    isOpenFuncDescription: false,
    isOpenFuncDescriptionLimitPrice: false,
    funcDescriptionData: null,
    funcDescriptionLimitPriceData: null,
    funcDescriptionHoveredData: null,
    funcDescriptionLimitPriceHoveredData: null,
};
var filterExpressionSlice = createSlice({
    name: 'filterExpressionSlice',
    initialState: initialState,
    reducers: {
        resetAll: function () { return initialState; },
        setFilterExpressionOpenedId: function (state, _a) {
            var payload = _a.payload;
            state.filterExpressionId = payload;
        },
        setIsOpenFuncDescription: function (state, _a) {
            var payload = _a.payload;
            state.isOpenFuncDescription = payload;
        },
        setIsOpenFuncDescriptionLimitPrice: function (state, _a) {
            var payload = _a.payload;
            state.isOpenFuncDescriptionLimitPrice = payload;
        },
        setFuncDescriptionData: function (state, _a) {
            var payload = _a.payload;
            state.funcDescriptionData = payload;
        },
        setFuncDescriptionLimitPriceData: function (state, _a) {
            var payload = _a.payload;
            state.funcDescriptionLimitPriceData = payload;
        },
        setIsOpenLimitPriceModal: function (state, _a) {
            var payload = _a.payload;
            state.isOpenLimitPriceModal = payload;
        },
        setFuncDescriptionHoveredData: function (state, _a) {
            var payload = _a.payload;
            state.funcDescriptionHoveredData = payload;
        },
        setFuncDescriptionLimitPriceHoveredData: function (state, _a) {
            var payload = _a.payload;
            state.funcDescriptionLimitPriceHoveredData = payload;
        },
    },
});
export default filterExpressionSlice.reducer;
export var resetAll = (_a = filterExpressionSlice.actions, _a.resetAll), setFilterExpressionOpenedId = _a.setFilterExpressionOpenedId, setIsOpenFuncDescription = _a.setIsOpenFuncDescription, setIsOpenFuncDescriptionLimitPrice = _a.setIsOpenFuncDescriptionLimitPrice, setFuncDescriptionData = _a.setFuncDescriptionData, setFuncDescriptionLimitPriceData = _a.setFuncDescriptionLimitPriceData, setIsOpenLimitPriceModal = _a.setIsOpenLimitPriceModal, setFuncDescriptionHoveredData = _a.setFuncDescriptionHoveredData, setFuncDescriptionLimitPriceHoveredData = _a.setFuncDescriptionLimitPriceHoveredData;
