var Socket = /** @class */ (function () {
    function Socket() {
        this.socket = null;
    }
    Socket.prototype.connect = function (url) {
        if (!this.socket) {
            this.socket = new WebSocket(url);
        }
    };
    Socket.prototype.disconnect = function () {
        if (this.socket) {
            this.socket.close();
            this.socket = null;
        }
    };
    Socket.prototype.send = function (message) {
        if (this.socket) {
            this.socket.send(JSON.stringify(message));
        }
    };
    Socket.prototype.on = function (event, callback) {
        if (this.socket) {
            this.socket.addEventListener(event, callback);
        }
    };
    return Socket;
}());
export { Socket };
