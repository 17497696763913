var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    messages: [],
    connected: false,
    connectionMessage: '',
};
var socketSlice = createSlice({
    name: 'socket',
    initialState: initialState,
    reducers: {
        connected: function (state, action) {
            state.connected = true;
            state.connectionMessage = action.payload.message;
        },
        messageReceived: function (state, action) {
            state.messages.push(action.payload);
        },
        disconnected: function (state) {
            state.connected = false;
        },
        clearMessages: function (state) {
            state.messages = [];
        },
    },
});
export var messageReceived = (_a = socketSlice.actions, _a.messageReceived), clearMessages = _a.clearMessages, disconnected = _a.disconnected, connected = _a.connected;
export default socketSlice.reducer;
