var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from './style';
import React, { forwardRef, useState } from 'react';
import { PasswordTypeEnum } from './types';
import { Space } from '@/shared/ui/Space/Space';
import { Text, Tooltip } from '@/shared/ui';
import { ReactComponent as Eye } from '../../assets/icons/eye.svg';
import { ReactComponent as EyeCross } from '../../assets/icons/eye-cross.svg';
import { ReactComponent as Paste } from '@/shared/assets/icons/PasteIcon.svg';
import { useTypedSelector } from '@/shared/hooks/useTypesSelector/useTypesSelector';
var Input = forwardRef(function (_a, ref) {
    var value = _a.value, css = _a.css, wrapperCSS = _a.wrapperCSS, searchIconCss = _a.searchIconCss, _b = _a.size, size = _b === void 0 ? 'large' : _b, autocomplete = _a.autocomplete, onChange = _a.onChange, onClick = _a.onClick, onKeyUp = _a.onKeyUp, iconInside = _a.iconInside, _c = _a.typeInput, typeInput = _c === void 0 ? 'default' : _c, type = _a.type, withLabel = _a.withLabel, setPasteFunction = _a.setPasteFunction, placeholder = _a.placeholder, error = _a.error, required = _a.required, label = _a.label, onBlur = _a.onBlur, onFocus = _a.onFocus, onKeyPress = _a.onKeyPress, autoFocus = _a.autoFocus, password = _a.password, tooltip = _a.tooltip, _d = _a.disabled, disabled = _d === void 0 ? false : _d, _e = _a.iconFilter, iconFilter = _e === void 0 ? false : _e, props = __rest(_a, ["value", "css", "wrapperCSS", "searchIconCss", "size", "autocomplete", "onChange", "onClick", "onKeyUp", "iconInside", "typeInput", "type", "withLabel", "setPasteFunction", "placeholder", "error", "required", "label", "onBlur", "onFocus", "onKeyPress", "autoFocus", "password", "tooltip", "disabled", "iconFilter"]);
    var localRef = React.useRef(null);
    var _f = useState(false), isInputFocused = _f[0], setIsInputFocused = _f[1];
    var _g = useState(PasswordTypeEnum.password), passwordType = _g[0], setPasswordType = _g[1];
    var _h = useState(false), isFocus = _h[0], setIsFocus = _h[1];
    var currentTheme = useTypedSelector(function (state) { return state.themeReducer.theme; });
    var handleChange = function (event) {
        if (onChange) {
            onChange(event);
        }
    };
    var changePasswordType = function () {
        setPasswordType(function (prevState) {
            return prevState === PasswordTypeEnum.password ? PasswordTypeEnum.text : PasswordTypeEnum.password;
        });
    };
    var getError = function () {
        var _a;
        return typeof error === 'object' && ((_a = error.message) === null || _a === void 0 ? void 0 : _a.trim()) !== '';
    };
    var handleFocus = function (event) {
        setIsInputFocused(true);
        if (onFocus) {
            onFocus(event);
        }
    };
    var handleBlur = function (event) {
        setIsInputFocused(false);
        if (onBlur) {
            onBlur(event);
        }
    };
    var handleKeyPress = function (event) {
        if (onKeyPress) {
            onKeyPress(event);
        }
    };
    var handleFocusInput = function () {
        setIsFocus(true);
    };
    var handleOnBlurInput = function (e) {
        if (!e.currentTarget.contains(e.relatedTarget)) {
            setIsFocus(false);
        }
    };
    return (_jsxs(Space, __assign({ css: { width: '100%', position: 'relative' }, direction: "vertical" }, { children: [_jsxs(S.InputWrapper, __assign({ "data-state-disabled": disabled, onBlur: handleOnBlurInput, onFocus: handleFocusInput, type: typeInput, error: getError(), css: wrapperCSS, size: size, onClick: function () { var _a; return (_a = localRef.current) === null || _a === void 0 ? void 0 : _a.focus(); } }, { children: [iconFilter && _jsx(S.SearchFilterIcon, { css: searchIconCss }), _jsxs("div", __assign({ style: {
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            flexGrow: 1,
                        } }, { children: [(withLabel && placeholder) &&
                                _jsx(S.Label, __assign({ "data-state-disabled": disabled, type: value || isInputFocused ? 'label' : 'placeholder', size: size }, { children: placeholder })), _jsx(S.Input, __assign({ disabled: disabled, size: size, withLabel: withLabel, type: type === PasswordTypeEnum.password ? passwordType : type, typeInput: typeInput, value: value ? value : '', error: getError(), onKeyUp: onKeyUp, onClick: onClick, iconInside: iconInside, onBlur: handleBlur, onFocus: handleFocus, "data-value-attribute": !!value, onKeyPress: handleKeyPress, placeholder: !withLabel ? placeholder : '', ref: function (node) {
                                    if (typeof ref === 'function') {
                                        ref(node);
                                    }
                                    else if (ref && 'current' in ref) {
                                        ref.current = node;
                                    }
                                    if (node) {
                                        Object.assign(localRef, { current: node });
                                    }
                                }, autoFocus: autoFocus, autoComplete: autocomplete || 'on', onChange: function (event) { return handleChange(event); }, css: __assign(__assign({}, css), { height: !isInputFocused && !value && withLabel ? '0px' : 'auto' }) }, props)), type === PasswordTypeEnum.password && (value || isFocus) && (_jsx(S.IconWrapper, __assign({ tabIndex: -1, type: "button", onClick: changePasswordType }, { children: passwordType === PasswordTypeEnum.password ? _jsx(Eye, { fill: currentTheme === 'dark' ? '#FFF' : '#000' }) :
                                    _jsx(EyeCross, { fill: currentTheme === 'dark' ? '#FFF' : '#000' }) }))), setPasteFunction && (_jsx(S.IconWrapper, __assign({ tabIndex: -1, onClick: setPasteFunction, css: { right: '8px' } }, { children: _jsx(Paste, { color: currentTheme === 'dark' ? '#FFF' : '#000' }) })))] })), tooltip && (_jsx(Tooltip, __assign({ content: tooltip, css: { width: '300px' } }, { children: _jsx("div", __assign({ style: { display: 'flex', alignItems: 'center' } }, { children: _jsx(S.HelpIcon, { style: { cursor: 'pointer' } }) })) })))] })), getError() &&
                _jsx(S.ErrorContainer, { children: _jsx(Text, __assign({ type: "errorInput" }, { children: error === null || error === void 0 ? void 0 : error.message })) })] })));
});
export { Input };
