var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { START_FETCH_STATUS } from '@/shared/api/constants/startFetch.status';
import { BASE_FETCH_STATUS } from '@/shared/api/constants/baseFetch.status';
var initialState = {
    inputDataId: 0,
    tickers: [],
    tickersStatus: BASE_FETCH_STATUS,
    selectedTickers: [],
    selectedTickersStatus: BASE_FETCH_STATUS,
    tickerOffset: 0,
    hasMore: true,
    companyDataTickers: null,
    companyDataTickersStatus: BASE_FETCH_STATUS,
    exchanges: [],
    exchangesStatus: BASE_FETCH_STATUS,
};
var getTickers = createAsyncThunk('inputDataSlice/getTickers', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var state, currentOffset, offset, params, resp, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                state = thunkAPI.getState();
                currentOffset = state.inputDataReducer.tickerOffset;
                offset = payload.step === 'first' ? 0 : currentOffset + 100;
                params = new URLSearchParams({
                    limit: payload.limit,
                    offset: offset.toString(),
                });
                if (payload.search) {
                    params.append('search', payload.search);
                }
                if (payload.exchange && payload.exchange !== 'All sources') {
                    params.append('exchange', payload.exchange);
                }
                _a.label = 1;
            case 1:
                _a.trys.push([1, 3, , 4]);
                return [4 /*yield*/, thunkAPI.extra.api.get("/strategy-block/".concat(payload.id, "/tickers/?").concat(params.toString()))];
            case 2:
                resp = _a.sent();
                return [2 /*return*/, { data: resp.data.results, step: payload.step, newOffset: offset }];
            case 3:
                e_1 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_1)];
            case 4: return [2 /*return*/];
        }
    });
}); });
var getSelectedTickers = createAsyncThunk('inputDataSlice/getSelectedTickers', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get("/strategy-block/".concat(payload.id, "/selected_tickers/?limit=").concat(payload.limit, "&offset=").concat(payload.offset))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_2 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_2)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getExchanges = createAsyncThunk('inputDataSlice/getExchanges', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get("/company-data/exchanges/?limit=".concat(payload.limit, "&offset=").concat(payload.offset))];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response.data];
            case 2:
                error_1 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(error_1)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var inputDataSlice = createSlice({
    name: 'inputDataSlice',
    initialState: initialState,
    reducers: {
        resetAll: function () { return initialState; },
        setInputDataOpenedId: function (state, _a) {
            var payload = _a.payload;
            state.inputDataId = payload;
        },
        clearSelectedTickers: function (state) {
            state.selectedTickers = [];
        },
        clearLoadedTickers: function (state) {
            state.tickers = [];
        },
        setSelectedTickers: function (state, _a) {
            var payload = _a.payload;
            state.selectedTickers = payload;
        },
        resetHasMore: function (state) {
            state.hasMore = true;
        },
        toggleSelectedTicker: function (state, _a) {
            var payload = _a.payload;
            if (!Array.isArray(state.selectedTickers)) {
                state.selectedTickers = [];
            }
            var exists = state.selectedTickers.find(function (el) { return el.id === payload.id; });
            if (exists) {
                state.selectedTickers = state.selectedTickers.filter(function (el) { return el.id !== payload.id; });
            }
            else {
                state.selectedTickers.push(payload);
            }
        },
    },
    extraReducers: function (builder) {
        builder.addCase(getTickers.pending, function (state) {
            state.tickersStatus = START_FETCH_STATUS;
        });
        builder.addCase(getTickers.rejected, function (state) {
            state.tickersStatus.isLoading = false;
        });
        builder.addCase(getTickers.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.tickersStatus.isLoading = false;
            if (payload.step === 'first') {
                state.tickers = payload.data;
                state.tickerOffset = 0;
            }
            else if (payload.step === 'new') {
                state.tickers = __spreadArray(__spreadArray([], state.tickers, true), payload.data, true);
                state.tickerOffset = payload.newOffset;
            }
            if (payload.data.length < 100) {
                state.hasMore = false;
            }
        });
        builder.addCase(getSelectedTickers.pending, function (state) {
            state.selectedTickersStatus = START_FETCH_STATUS;
        });
        builder.addCase(getSelectedTickers.rejected, function (state) {
            state.selectedTickersStatus.isLoading = false;
        });
        builder.addCase(getSelectedTickers.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.selectedTickersStatus.isLoading = false;
            state.selectedTickers = payload.results ? payload.results : [];
        });
        builder.addCase(getExchanges.pending, function (state) {
            state.exchangesStatus = START_FETCH_STATUS;
        });
        builder.addCase(getExchanges.rejected, function (state) {
            state.exchangesStatus.isLoading = false;
        });
        builder.addCase(getExchanges.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.exchangesStatus.isLoading = false;
            state.exchanges = payload.exchanges ? payload.exchanges : [];
        });
    },
});
export default inputDataSlice.reducer;
export var resetAll = (_a = inputDataSlice.actions, _a.resetAll), setInputDataOpenedId = _a.setInputDataOpenedId, toggleSelectedTicker = _a.toggleSelectedTicker, clearSelectedTickers = _a.clearSelectedTickers, resetHasMore = _a.resetHasMore, setSelectedTickers = _a.setSelectedTickers, clearLoadedTickers = _a.clearLoadedTickers;
export { getTickers, getSelectedTickers, getExchanges };
