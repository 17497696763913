import { styled } from '@/app/styles/mainStyles';
import { Text } from '@/shared/ui/Text/style';
import { ReactComponent as Warning } from '@/shared/assets/icons/Warning.svg';
import { ReactComponent as Attention } from '@/shared/assets/icons/Attention.svg';
export var InformerWrapper = styled('div', {
    display: 'flex',
    gap: '12px',
    padding: '20px',
    borderRadius: '20px',
    alignItems: 'center',
    boxSizing: 'border-box',
    '@mobile': {
        padding: '8px 12px',
        borderRadius: '12px',
        gap: '8px',
    },
    variants: {
        type: {
            attention: {
                border: '1px solid $controlsStrokeAttention',
                background: '$bgAttentionLight',
                alignItems: 'center',
            },
            info: {
                border: '1px solid $controlsBgAccentDefault',
                background: '$bgAccent',
            },
            warning: {
                border: '1px solid $controlsStrokeError',
                background: '$bgError',
            },
        },
        alignIcon: {
            start: {
                alignItems: 'start !important',
            },
            center: {
                alignItems: 'center !important',
            },
            end: {
                alignItems: 'end !important',
            },
        },
    },
});
export var InformerButton = styled('p', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 8px !important',
    height: '36px',
    flexShrink: 0,
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '22px',
    color: '$textLinkDefault',
});
export var ContentWrapper = styled('div', {
    display: 'flex',
    gap: '40px',
});
export var InformerText = styled(Text, {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '22px',
    '@mobile': {
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 400,
    },
});
export var WarningIcon = styled(Warning, {
    width: '20px',
    height: '20px',
    flexShrink: 0,
    '@mobile': {
        width: '16px',
        height: '16px',
        marginTop: '2px',
    },
});
export var AttentionIcon = styled(Attention, {
    width: '20px',
    height: '20px',
    flexShrink: 0,
    '@mobile': {
        width: '16px',
        height: '16px',
        marginTop: '2px',
    },
});
