var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from './style';
import React, { forwardRef } from 'react';
import { Space, Text } from '@/shared/ui';
var TextArea = forwardRef(function (_a, ref) {
    var value = _a.value, css = _a.css, autocomplete = _a.autocomplete, onChange = _a.onChange, placeholder = _a.placeholder, withLabel = _a.withLabel, onChangeText = _a.onChangeText, typeInput = _a.typeInput, error = _a.error, disabled = _a.disabled, onBlur = _a.onBlur, props = __rest(_a, ["value", "css", "autocomplete", "onChange", "placeholder", "withLabel", "onChangeText", "typeInput", "error", "disabled", "onBlur"]);
    var getError = function () {
        return typeof error === 'object';
    };
    var handleChange = function (event) {
        if (onChange) {
            if (onChangeText) {
                onChangeText(event.target.value);
            }
            onChange(event);
        }
    };
    var handleBlur = function (event) {
        if (onBlur) {
            onBlur(event);
        }
    };
    return (_jsxs(Space, __assign({ css: { position: 'relative' }, direction: "vertical" }, { children: [_jsx(S.TextArea, __assign({ typeInput: typeInput, value: value, placeholder: !withLabel ? placeholder : '', withLabel: withLabel, "data-value-attribute": !!value, ref: ref, onBlur: handleBlur, autoComplete: autocomplete ? 'on' : 'off', error: error && true, disabled: disabled, onChange: function (event) { return handleChange(event); }, css: css }, props)), (withLabel && placeholder) &&
                _jsx(S.Label, __assign({ "data-value-attribute": !!value }, { children: placeholder })), getError() &&
                _jsx(S.ErrorContainer, { children: _jsx(Text, __assign({ type: "errorInput" }, { children: error === null || error === void 0 ? void 0 : error.message })) })] })));
});
export { TextArea };
