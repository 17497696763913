var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment } from 'react';
import * as S from './style';
import { useTypedSelector } from '@/shared/hooks/useTypesSelector/useTypesSelector';
var Tabs = function (_a) {
    var tabs = _a.tabs, active = _a.active, onClick = _a.onClick, _b = _a.type, type = _b === void 0 ? 'default' : _b, _c = _a.styleType, styleType = _c === void 0 ? 'default' : _c, css = _a.css, inputTabCss = _a.inputTabCss;
    var currentTheme = useTypedSelector(function (state) { return state.themeReducer.theme; });
    var changeTab = function (value) {
        onClick(value);
    };
    return (_jsx(S.Tabs, __assign({ styleType: styleType, css: css }, { children: tabs.map(function (item) {
            var _a, _b, _c, _d;
            return (_jsxs(Fragment, { children: [_jsx(S.TabsInput, { id: item.id, type: "radio", checked: active === item.id, onChange: function () { return changeTab(item); }, styleType: styleType }), _jsxs(S.TabsLabel, __assign({ type: type, htmlFor: item.id, css: inputTabCss }, { children: [active === item.id
                                ? currentTheme === 'dark'
                                    ? (_a = item.icon) === null || _a === void 0 ? void 0 : _a.black
                                    : (_b = item.icon) === null || _b === void 0 ? void 0 : _b.white
                                : currentTheme === 'dark'
                                    ? (_c = item.icon) === null || _c === void 0 ? void 0 : _c.white
                                    : (_d = item.icon) === null || _d === void 0 ? void 0 : _d.black, item.value] }))] }, item.id));
        }) })));
};
export { Tabs };
