var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASE_FETCH_STATUS } from '@/shared/api/constants/baseFetch.status';
import { START_FETCH_STATUS } from '@/shared/api/constants/startFetch.status';
var initialState = {
    data: null,
    getDataStatus: BASE_FETCH_STATUS,
    updateOracleStatus: BASE_FETCH_STATUS,
    oracleId: null,
    oracles: null,
};
var updateOracles = createAsyncThunk('oracleSlice/updateOracles', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.post("/strategy-block/".concat(payload.id, "/oracles/"), payload.data)];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_1 = _a.sent();
                return [2 /*return*/, thunkAPI.rejectWithValue(e_1)];
            case 3: return [2 /*return*/];
        }
    });
}); });
var getOracleData = createAsyncThunk('oracleSlice/getOracleData', function (payload, thunkAPI) { return __awaiter(void 0, void 0, void 0, function () {
    var resp, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, thunkAPI.extra.api.get("/strategy-block/".concat(payload.id, "/oracles/"))];
            case 1:
                resp = _a.sent();
                return [2 /*return*/, resp.data];
            case 2:
                e_2 = _a.sent();
                return [2 /*return*/, null];
            case 3: return [2 /*return*/];
        }
    });
}); });
var oracleSlice = createSlice({
    name: 'oracleSlice',
    initialState: initialState,
    reducers: {
        setOraclesOnStrategyOpen: function (state, _a) {
            var payload = _a.payload;
            state.oracles = payload;
        },
        removeOracle: function (state, _a) {
            var nodeId = _a.payload;
            if (state.oracles) {
                delete state.oracles[nodeId];
            }
        },
        addOrUpdateOracle: function (state, _a) {
            var _b;
            var payload = _a.payload;
            var nodeId = payload.nodeId, value = payload.value;
            if (state.oracles) {
                state.oracles[nodeId] = value;
            }
            else {
                state.oracles = (_b = {}, _b[nodeId] = value, _b);
            }
        },
        setOracleId: function (state, _a) {
            var payload = _a.payload;
            state.oracleId = payload;
        },
    },
    extraReducers: function (builder) {
        builder
            .addCase(updateOracles.pending, function (state, action) {
            state.updateOracleStatus = START_FETCH_STATUS;
            state.data = action.payload;
        })
            .addCase(updateOracles.fulfilled, function (state, action) {
            state.updateOracleStatus.isLoading = false;
            state.data = action.payload;
        })
            .addCase(updateOracles.rejected, function (state, action) {
            state.updateOracleStatus.isLoading = false;
            state.data = action.payload;
        })
            .addCase(getOracleData.pending, function (state) {
            state.getDataStatus = START_FETCH_STATUS;
        })
            .addCase(getOracleData.rejected, function (state, action) {
            state.getDataStatus.isLoading = false;
        })
            .addCase(getOracleData.fulfilled, function (state, action) {
            state.getDataStatus.isLoading = false;
            state.data = action.payload;
        });
    },
});
export default oracleSlice.reducer;
export var setOracleId = (_a = oracleSlice.actions, _a.setOracleId), setOraclesOnStrategyOpen = _a.setOraclesOnStrategyOpen, removeOracle = _a.removeOracle, addOrUpdateOracle = _a.addOrUpdateOracle;
export { updateOracles, getOracleData };
