var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from '@/shared/assets/icons/close.svg';
import * as S from './style';
export var Dialog = forwardRef(function (_a, ref) {
    var open = _a.open, onOpenChange = _a.onOpenChange, trigger = _a.trigger, children = _a.children, closeIcon = _a.closeIcon, css = _a.css, titleCss = _a.titleCss, descriptionCss = _a.descriptionCss, cssHeader = _a.cssHeader, className = _a.className, outside = _a.outside, description = _a.description, title = _a.title, container = _a.container, disableHeader = _a.disableHeader, _b = _a.showOverlay, showOverlay = _b === void 0 ? true : _b, light = _a.light, disableAutofocus = _a.disableAutofocus;
    var _c = useState(null), modalContainer = _c[0], setModalContainer = _c[1];
    var close = function () {
        if (onOpenChange) {
            onOpenChange(false);
        }
    };
    var onCloseClickOutside = function () {
        if (outside) {
            return;
        }
        if (onOpenChange) {
            onOpenChange(false);
        }
    };
    var handleClickOutside = function (event) {
        if (outside) {
            event.preventDefault();
        }
    };
    useEffect(function () {
        if (open) {
            document.body.style.overflowY = 'hidden';
        }
        return function () {
            document.body.style.overflowY = 'auto';
        };
    }, [open]);
    useEffect(function () {
        var mainContainer = document.querySelector('.main-container');
        setModalContainer(mainContainer);
    }, []);
    return (_jsxs(S.Root, __assign({ open: open, onOpenChange: onOpenChange }, { children: [trigger && _jsx(S.Trigger, __assign({ asChild: true }, { children: trigger })), _jsxs(S.Portal, __assign({ container: modalContainer }, { children: [_jsx(S.Overlay, { onClick: onCloseClickOutside, style: {
                            background: showOverlay ? light ? 'rgba(243, 245, 246, 0.80)' : 'rgba(0, 0, 0, 0.7)' : 'rgba(0, 0, 0, 0.0)',
                        } }), _jsxs(S.Content, __assign({ onOpenAutoFocus: function (e) {
                            if (disableAutofocus) {
                                e.preventDefault();
                            }
                        }, onCloseAutoFocus: handleClickOutside, onPointerDownOutside: handleClickOutside, onInteractOutside: handleClickOutside, css: css, className: className, type: container }, { children: [!disableHeader && (_jsxs(S.Header, __assign({ type: container, css: cssHeader }, { children: [_jsxs(S.HeaderText, { children: [title &&
                                                _jsx(S.Title, __assign({ css: titleCss, "data-description": !!description }, { children: title })), description &&
                                                _jsx(S.Description, __assign({ css: descriptionCss }, { children: description }))] }), closeIcon && (_jsx(S.Close, __assign({ tabIndex: -1, onClick: function () {
                                            close();
                                        } }, { children: _jsx(CloseIcon, {}) })))] }))), children, !children && _jsx("div", { children: "Empty content" })] }))] }))] })));
});
