import { styled } from '@/app/styles/mainStyles';
import { ReactComponent as Arrow } from '@/shared/assets/icons/Arrow.svg';
import { ReactComponent as Settings } from '@/shared/assets/icons/Settings.svg';
import { ReactComponent as Help } from '@/shared/assets/icons/help24.svg';
export var SelectWrapper = styled('div', {
    boxSizing: 'border-box',
    position: 'relative',
    outline: 'none',
    variants: {
        disabled: {
            true: {
                // cursor: 'not-allowed',
                pointerEvents: 'none',
            },
            false: {
                cursor: 'pointer !important',
                pointerEvents: 'all',
            },
        },
    },
});
export var Select = styled('div', {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    background: '$bgInput',
    position: 'relative',
    cursor: 'pointer !important',
    transition: 'border 0.2s',
    variants: {
        border: {
            default: {
                border: '1px solid $bgInput',
                '&:hover': {
                    border: '1px solid $controlsStrokeHover',
                },
                '&:focus-within, &:active': {
                    border: '1px solid $controlsStrokeFocus',
                },
            },
            error: {
                border: '1px solid $inputBorderError',
                '&:hover': {
                    border: '1px solid $inputBorderError',
                },
                '&:focus-within, &:active': {
                    border: '1px solid $inputBorderError',
                },
            },
        },
        size: {
            small: {
                border: '1px solid $controlsStrokeDefault',
                height: '36px',
                padding: '0px 9px',
                borderRadius: '8px',
            },
            medium: {
                height: '48px',
                padding: '0px 15px',
                borderRadius: '8px',
            },
            large: {
                height: '60px',
                padding: '0px 15px',
                borderRadius: '16px',
            },
        },
    },
});
export var SelectInput = styled('input', {
    boxSizing: 'border-box',
    padding: '0px',
    width: '100%',
    background: '$bgInput',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    fontFamily: '$montserrat',
    fontWeight: '400',
    color: '$primaryText',
    transition: 'border 0.2s',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    variants: {
        padding: {
            default: {
                padding: '0px',
            },
            small: {
            // padding: '24px 40px 8px 16px',
            },
            smaller: {
            // padding: '7px 10px',
            },
        },
        size: {
            small: {
                fontSize: '16px',
                lineHeight: '22px',
            },
            medium: {
                fontSize: '16px',
                lineHeight: '22px',
            },
            large: {
                fontSize: '20px',
                lineHeight: '28px',
            },
        },
    },
});
export var SelectedName = styled('div', {
    boxSizing: 'border-box',
    position: 'absolute',
    top: '24px',
    left: '16px',
    width: '182px',
    fontSize: '20px',
    fontWeight: '400',
    lineHeight: '28px',
    fontFamily: '$montserrat',
    background: '$bgInput',
    color: '$primaryText',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});
export var SelectedValue = styled('div', {
    boxSizing: 'border-box',
    position: 'absolute',
    top: '8px',
    left: '16px',
    width: '150px',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '16px',
    fontFamily: '$montserrat',
    color: '$textSecondary',
    background: '$bgInput',
});
export var SelectLabel = styled('label', {
    boxSizing: 'border-box',
    fontWeight: '400',
    fontFamily: '$montserrat',
    color: '$textSecondary',
    transition: 'all 0.2s',
    variants: {
        type: {
            placeholder: {},
            label: {},
        },
        size: {
            small: {},
            medium: {},
            large: {},
        },
        disabled: {
            true: {
                color: '$textTertiaryDisabled',
            },
            false: {
                color: '$textSecondary',
            },
        },
    },
    compoundVariants: [
        { type: 'label', size: 'small', css: { fontSize: '16px', lineHeight: '22px' },
        },
        { type: 'label', size: 'medium', css: { fontSize: '12px', lineHeight: '16px' },
        },
        { type: 'label', size: 'large', css: { fontSize: '12px', lineHeight: '16px' },
        },
        { type: 'placeholder', size: 'small', css: { fontSize: '16px', lineHeight: '22px' },
        },
        { type: 'placeholder', size: 'medium', css: { fontSize: '16px', lineHeight: '22px' },
        },
        { type: 'placeholder', size: 'large', css: { fontSize: '20px', lineHeight: '28px' },
        },
    ],
});
export var SelectIcon = styled('div', {
    display: 'inline-flex',
    pointerEvents: 'none',
    '& svg': {
        transition: 'transform 0.3s',
    },
    '&[data-state-open="true"]': {
        '& svg': {
            transform: 'rotate(-180deg)',
        },
    },
    variants: {
        disabled: {
            true: {
                '& svg': {
                    transform: 'rotate(0deg) !important',
                },
            },
        },
    },
});
export var Icon = styled('div', {
    boxSizing: 'border-box',
    width: '16px',
    height: '16px',
});
export var CrossIcon = styled('div', {
    display: 'inline-flex',
    zIndex: 99,
    boxSizing: 'border-box',
    borderRadius: '6px',
    '&:hover': {
        backgroundColor: '$controlsBgSecondaryHover',
        cursor: 'pointer',
    },
});
export var SelectSettingIcon = styled('div', {
    display: 'inline-flex',
    position: 'absolute',
    zIndex: 99999999,
    top: '50%',
    right: '44px',
    cursor: 'pointer',
    transform: 'translateY(-50%)',
    padding: '4px',
    borderRadius: '6px',
    transition: 'background-color 0.2s',
    '& svg': {
        transition: 'transform 0.6s',
    },
    '&:hover': {
        backgroundColor: '$controlsBgSecondaryHover',
        '& svg': {
            transform: 'rotate(-180deg)',
        },
    },
});
export var SelectDropdown = styled('div', {
    boxSizing: 'border-box',
    pointerEvents: 'all',
    overflow: 'hidden',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    top: 0,
    width: '100%',
    minWidth: '100px',
    background: '$bgSecondlayer',
    borderRadius: '8px',
    boxShadow: '$modalsShadow',
    zIndex: '9999999999',
    maxHeight: '298px',
    '&[data-state-left-position="true"]': {
        right: 0,
    },
});
export var SelectDropdownOptions = styled('div', {
    boxSizing: 'border-box',
    pointerEvents: 'all',
    padding: '8px',
    width: '100%',
    maxHeight: '298px',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    color: '$primaryText',
    background: '$bgSecondlayer',
    '&::-webkit-scrollbar': {
        width: '16px',
        background: '$bgSecondlayer',
        borderRadius: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
        border: '3px solid $bgSecondlayer',
        background: '$controlsBgSecondaryDefault',
        borderRadius: '8px',
    },
});
export var SelectDropdownOption = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    borderRadius: '8px',
    padding: '8px 16px',
    fontWeight: 400,
    transition: 'background-color 0.2s',
    cursor: 'pointer',
    '&[data-state-select="true"]': {
        backgroundColor: '$controlsBgAccentDefault',
        color: '$alwaysWhite',
    },
    '&:hover:not(&[data-state-select="true"])': {
        backgroundColor: '$controlsBgSecondaryDefault',
    },
    variants: {
        size: {
            small: {
                fontSize: '16px',
                lineHeight: '22px',
            },
            medium: {
                fontSize: '16px',
                lineHeight: '22px',
            },
            large: {
                fontSize: '20px',
                lineHeight: '28px',
            },
        },
    },
});
export var SelectDropdownOptionHint = styled('p', {
    color: '$darkText',
});
export var ArrowIcon = styled(Arrow, {
    width: 24,
    height: 24,
    color: '$textTertiaryDisabled',
    variants: {
        disabled: {
            true: {
                color: '$textTertiaryDisabled',
            },
            false: {
                color: '$primaryText',
            },
        },
    },
});
export var SettingsIcon = styled(Settings, {
    color: '$primaryText',
});
export var IconDescriptionContainer = styled('div', {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '28px',
    height: '28px',
    borderRadius: '6px',
    transition: '0.2s',
    '&:hover': {
        background: '$controlsBgSecondaryHover',
    },
});
export var HelpIcon = styled(Help, {
    boxSizing: 'border-box',
    color: '$textTertiaryDisabled',
});
export var HotkeyContainer = styled('div', {
    boxSizing: 'border-box',
    background: '$bgSecondary',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    width: '100%',
    padding: '7px 24px 8px 24px',
    borderTop: '1px solid $divider',
});
