export var BorderType;
(function (BorderType) {
    BorderType["ERROR"] = "error";
    BorderType["DEFAULT"] = "default";
})(BorderType || (BorderType = {}));
export var LabelType;
(function (LabelType) {
    LabelType["LABEL"] = "label";
    LabelType["PLACEHOLDER"] = "placeholder";
})(LabelType || (LabelType = {}));
export var InputType;
(function (InputType) {
    InputType["SMALLER"] = "smaller";
    InputType["SMALL"] = "small";
    InputType["DEFAULT"] = "default";
})(InputType || (InputType = {}));
