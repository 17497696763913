import { styled } from '@/app/styles/mainStyles';
import { ReactComponent as SearchFilter } from '@/shared/assets/icons/searchFilter.svg';
import { ReactComponent as Help } from '@/shared/assets/icons/help24.svg';
export var InputWrapper = styled('div', {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    width: '100%',
    border: '1px solid transparent',
    background: '$bgInput',
    position: 'relative',
    cursor: 'text',
    transition: '0.2s',
    '&:hover': {
        border: '1px solid $controlsStrokeHover',
    },
    '&:focus-within, &:active': {
        border: '1px solid $controlsStrokeFocus',
    },
    variants: {
        type: {
            default: {
                '&:focus': {
                    '& + span': {
                        top: '12px',
                        fontSize: '12px',
                    },
                },
            },
            auth: {},
            filterSearch: {},
            filerInput: {},
        },
        error: {
            true: {
                outline: 'none',
                border: '1px solid $inputBorderError',
                transition: '0.5s',
                '&:hover': {
                    border: '1px solid $inputBorderError',
                },
                '&:focus': {
                    border: '1px solid $inputBorderError',
                },
            },
        },
        size: {
            small: {
                border: '1px solid $controlsStrokeDefault',
                height: '36px',
                padding: '0px 9px',
                borderRadius: '8px',
            },
            medium: {
                height: '48px',
                padding: '0px 15px',
                borderRadius: '8px',
            },
            large: {
                height: '60px',
                padding: '0px 15px',
                borderRadius: '16px',
            },
        },
    },
    '&[data-state-disabled="true"]': {
        '&:hover': {
            outline: '1px solid transparent',
        },
    },
});
export var Label = styled('p', {
    boxSizing: 'border-box',
    pointerEvents: 'none',
    fontSize: '20px',
    lineHeight: '28px',
    color: '$textSecondary',
    transition: 'font-size 0.2s',
    '@mobile': {
        fontSize: '16px',
    },
    '&[data-state-disabled="true"]': {
        color: '$textDisabled',
    },
    variants: {
        type: {
            placeholder: {},
            label: {},
        },
        size: {
            small: {},
            medium: {},
            large: {},
        },
    },
    compoundVariants: [
        { type: 'label', size: 'small', css: { fontSize: '16px', lineHeight: '22px' },
        },
        { type: 'label', size: 'medium', css: { fontSize: '12px', lineHeight: '16px' },
        },
        { type: 'label', size: 'large', css: { fontSize: '12px', lineHeight: '16px' },
        },
        { type: 'placeholder', size: 'small', css: { fontSize: '16px', lineHeight: '22px' },
        },
        { type: 'placeholder', size: 'medium', css: { fontSize: '16px', lineHeight: '22px' },
        },
        { type: 'placeholder', size: 'large', css: { fontSize: '20px', lineHeight: '28px' },
        },
    ],
});
export var Input = styled('input', {
    boxSizing: 'border-box',
    padding: '0px',
    width: '100%',
    background: '$bgInput !important',
    outline: 'none',
    border: 'none',
    cursor: 'text',
    fontWeight: '400',
    fontFamily: '$montserrat',
    color: '$primaryText',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    transition: '0.2s',
    '&::placeholder': {
        color: '$textSecondary',
    },
    '&::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
        appearance: 'none',
        MozAppearance: 'textfield',
    },
    '&::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
        appearance: 'none',
        MozAppearance: 'textfield',
    },
    '&[type="password"]': {
        paddingRight: '56px',
    },
    variants: {
        size: {
            small: {
                fontSize: '16px',
                lineHeight: '22px',
            },
            medium: {
                fontSize: '16px',
                lineHeight: '22px',
            },
            large: {
                fontSize: '20px',
                lineHeight: '28px',
            },
        },
        typeInput: {
            default: {
                '&::placeholder': {
                    color: '$textSecondary',
                },
            },
            auth: {},
            filterSearch: {
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                alignSelf: 'stretch',
                fontWeight: '400',
                padding: '13px 10px 13px 46px',
                height: '36px',
                borderRadius: '8px',
                border: '1px solid $controlsStrokeDefault',
                '&::placeholder': {
                    color: '$textSecondary',
                },
            },
            filerInput: {
                boxSizing: 'border-box',
                border: '1px solid $controlsStrokeDefault',
                height: '36px',
                borderRadius: '8px',
                color: '$primaryText',
                padding: '7px 10px',
                fontSize: '16px',
                lineHeight: '22px',
                fontWeight: 400,
                '&::placeholder': {
                    color: '$textSecondary',
                },
            },
        },
        withLabel: {
            true: {},
        },
        iconInside: {
            true: {
            // backgroundImage: `url(${Search})`,
            // backgroundRepeat: 'no-repeat',
            // backgroundSize: '18px 18px',
            // backgroundPosition: 'left 6px center',
            // paddingLeft: '30px',
            },
        },
        disabled: {
            true: {
                pointerEvents: 'none',
                opacity: '0.8',
                color: '$textDisabled',
                '&::placeholder': {
                    color: '$textDisabled',
                },
            },
        },
        error: {
            true: {
                outline: 'none',
                border: 'none',
                '&:hover': {
                    outline: 'none',
                    border: 'none',
                },
                '&:focus': {
                    outline: 'none',
                    border: 'none',
                },
            },
        },
        // isInputFocused: {
        //   true: {
        //     position: 'absolute',
        //     top: '0',
        //   },
        // },
    },
    '&:-webkit-autofill': {
        transition: '5000s ease-in-out 0s',
    },
    '&:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active': {
        transition: '5000s ease-in-out 0s',
    },
    '&:disabled': {
        '&::placeholder': {
            color: '$textDisabled',
        },
    },
});
export var ErrorContainer = styled('div', {
    padding: '0 16px',
    marginTop: '4px',
    '@mobile': {
        display: 'flex',
        alignItems: 'start',
    },
    '@tablet': {
        display: 'flex',
        alignItems: 'start',
    },
});
export var IconWrapper = styled('button', {
    display: 'inline-flex',
    position: 'absolute',
    top: '50%',
    right: '16px',
    padding: '8px',
    background: '$bgInput',
    border: 'none',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    transition: 'background .2s',
    borderRadius: '8px',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
        background: '#D0D5DD',
    },
});
export var SearchFilterIcon = styled(SearchFilter, {
    boxSizing: 'border-box',
    color: '$textSecondary',
    width: '16px',
    height: '16px',
});
export var HelpIcon = styled(Help, {
    boxSizing: 'border-box',
    color: '$textTertiaryDisabled',
});
